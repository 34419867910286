import React from 'react';
import './css/Careers.css'; // Import the CSS file

function Careers() {
  return (
    <div className="careers-container">
      {/* About Careers Section */}
      <section className="about-careers-section section-light">
        <div className="about-careers-content">
          <h2>Careers at Your Way Specialist Support Services</h2>
          <p>
            At Your Way, we are dedicated to providing exceptional care and support to our clients. We believe that our team is our greatest asset, and we are committed to fostering a positive, inclusive work environment. Whether you're a seasoned professional or just starting out in the care sector, we welcome passionate individuals to join our team.
          </p>
        </div>
      </section>

      {/* No Job Listings Section */}
      <section className="no-jobs-section section-dark">
        <div className="no-jobs-content">
          <h3>No Current Job Openings</h3>
          <p>We currently do not have any job openings available, but we are always interested in hearing from talented and compassionate individuals. If you'd like to be considered for future opportunities, please send your CV to <a href="mailto:tony@yourwayss.com">tony@yourwayss.com</a>.</p>
        </div>
      </section>

      {/* Join Our Team Section */}
      <section className="join-team-section section-light">
        <div className="join-team-content">
          <h2>Join Our Talent Pool</h2>
          <p>Stay connected with us! Join our talent pool and be the first to know about new opportunities. Simply send us your details, and we'll keep you informed.</p>
          <a href="mailto:tony@yourwayss.com" className="btn btn-primary">Contact Us</a>
        </div>
      </section>
    </div>
  );
}

export default Careers;
