import React from 'react';
import './css/AboutUs.css';
import { FaCheckCircle, FaHandHoldingHeart, FaUsers, FaLeaf } from 'react-icons/fa'; // Import icons
import yourway from './assets/img/Your Way - Logo 2.png';
import ceo from './assets/img/tony.png';

function AboutUs() {
  return (
    <div className="about-us-container">
      {/* About Us Section */}
      <section className="section section-light" style={{ paddingTop: '150px' }}>
        <div className="about-us-content">
          <div className="about-us-section">
            <div className="circular-image-container">
              <img src={yourway} alt="CEO" className="circular-image" style={{ width: '250px', height: '250px' }} /> {/* Increased size */}
            </div>
            <p>
              At Your Way, we are committed to providing exceptional, personalised care that empowers individuals to live with dignity and independence. Though we are a new company, our mission is clear: to make a positive difference in the lives of those we serve.
              We are in the process of building a team of dedicated professionals who share our passion for quality care. Our focus is on offering personalised support that respects the dignity and individuality of every person. At Your Way, we believe in creating a nurturing environment where every client feels valued and supported. Together, we will help you or your loved ones live life to the fullest.
            </p>
          </div>
        </div>
      </section>

      {/* Mission Section Centered with Icons */}
      <section className="section section-purple" style={{ textAlign: 'center' }}>
        <div className="about-us-content">
          <div className="about-us-section" style={{ maxWidth: '800px', margin: '0 auto' }}>
            <h3 className="section-title">OUR MISSION</h3>
            <p>
              Our mission is to deliver exceptional care and support tailored to the preferences and needs of our service users. We are committed to training and empowering our staff to the highest standards, ensuring they are equipped with the skills and motivation necessary to provide compassionate, person-centered care in a positive and supportive work environment.
            </p>
            <div className="mission-list">
              <div className="mission-item">
                <FaCheckCircle className="mission-icon" />
                <div className="mission-text">
                  <h4>Choice</h4>
                  <p>We will empower each individual to make decisions about their
                    own care and support, ensuring care is personalised, respectful, and
                    aligned with their unique needs and preferences.</p>
                </div>
              </div>
              <div className="mission-item">
                <FaHandHoldingHeart className="mission-icon" />
                <div className="mission-text">
                  <h4>High Quality Care and Support</h4>
                  <p>We will provide services that are safe,
                    effective, and centered around the needs and preferences of individuals.
                    We will deliver compassionate care that respects dignity and promotes
                    independence, while also being responsive to changing needs. We will
                    ensure our services are accessible, equitable, and delivered by well-trained staff who work collaboratively across disciplines. Our approach
                    will foster a culture of continuous improvement, using feedback and
                    evidence-based practices to enhance outcomes, satisfaction, and overall
                    well-being for those receiving care and support.</p>
                </div>
              </div>
              <div className="mission-item">
                <FaUsers className="mission-icon" />
                <div className="mission-text">
                  <h4>Partnership</h4>
                  <p>We will focus on collaboration between Internal support
                    services, social care services, healthcare services, families, and
                    communities to deliver comprehensive, coordinated support for our
                    Service Users. By working together, we can share knowledge, resources,
                    and expertise to address the holistic needs of individuals, ensuring care
                    and support is integrated and person-centered. Effective partnerships
                    enhance communication, reduce duplication of services, and promote
                    continuity of care, leading to better outcomes for the Service user.
                  </p>
                </div>
              </div>
              <div className="mission-item">
                <FaLeaf className="mission-icon" />
                <div className="mission-text">
                  <h4>Social Impact</h4>
                  <p> As an organisation our intention is to support local
                    communities as part of our offer. This will involve sponsoring local sports
                    teams and clubs, putting on events for the community, recruiting locally,
                    and using local suppliers. We hope these efforts will help our service users
                    integrate and become valuable members of the community in which they
                    live.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Vision Section */}
      <section className="section section-light" style={{ textAlign: 'center' }}>
        <div className="about-us-content">
          <div className="about-us-section" style={{ maxWidth: '800px', margin: '0 auto' }}>
            <h3 className="section-title">OUR VISION</h3>
            <p>
              Our vision is to give people with Learning Disabilities and Autism the opportunity to
              live in their own bespoke tenancy, to support each individual to develop and
              maintain positive relationships and to deliver the support that each individual
              requires to be safe, healthy and an active part of the society in which they live.
            </p>
          </div>
        </div>
      </section>

      {/* Meet Our Team Section */}
      <section className="section section-dark">
        <h2 className="team-heading" style={{ textAlign: 'center' }}>Meet Our Team</h2>
        <div className="team-container" style={{ justifyContent: 'center' }}>
          <div className="team-card">
            <div className="team-card-content">
              <img src={ceo} alt="Tony Mulhearn" className="team-image" style={{ width: '170px', height: '170px' }} /> {/* Increased size */}
              <h3 style={{ color: 'white' }}>Tony Mulhearn</h3>
              <p style={{ color: 'white' }}>Chief Executive Officer</p>
              <a href="https://www.linkedin.com/in/tony-mulhearn-073788102/" target="_blank" rel="noopener noreferrer">
                <i className="bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="team-card team-quote-card">
            <p style={{ color: 'white' }}> {/* Changed text color to black */}
            Tony began his journey at 16, joining the Royal Engineers as a Plant Operator Mechanic. After serving three years in the military, he transitioned into the care sector, where he has built an impressive career spanning over 23 years. Starting as a Support Worker for individuals with Learning Disabilities and Autism, Tony progressed through various roles, ultimately leading and managing organizations that offer a range of services, including Domiciliary Care, Residential and Nursing Services, and Supported Living.

Tony's true passion lies in Supported Living, especially in advocating for those who are often overlooked and placed in long-stay hospitals or unsuitable environments. This commitment has been the cornerstone of his career, and with Your Way Specialist Support Services, he is dedicated to delivering exceptional care and support to the most vulnerable members of society for many years to come.
            </p>
          </div>
        </div>
      </section>

      {/* Footer Section */}
  
    </div>
  );
}

export default AboutUs;
