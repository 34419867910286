import React from 'react';
import './css/News.css'; // Ensure you have a News-specific CSS file
import pic from './assets/img/Yourway-cover.png'; // Correct image import

function NewsPage() {
  const articles = [
    {
      title: "Exciting Developments in Your Way Support Services",
      date: "September 15, 2024",
      content: "Your Way Specialist Support Services, founded by Tony Mulhearn, is a new and innovative care organization dedicated to providing high-quality, personalized support to individuals with learning disabilities and autism. Tony, with over 23 years of experience in the care sector, started the business with a passion for improving the lives of the most vulnerable members of society. Currently in its development phase, Your Way is building a team of dedicated professionals who share Tony’s vision of delivering exceptional care. The organization is focused on creating bespoke tenancy options, supported living, and specialist care services that respect the dignity and individuality of each person.Tony’s commitment to excellence and his extensive experience in managing various care services, including domiciliary care, residential, and nursing services, are the driving forces behind Your Way. The business is not just about care; it’s about offering support ‘Your Way,’ tailored to the unique needs and preferences of each service user. As the business continues to grow and develop, Your Way is set to become a beacon of quality and compassion in the care sector, offering innovative and impactful support services that truly make a difference in the lives of those they serve.  ",
      image: pic, // Directly assign the imported image
    },
   
  ];

  return (
    <div className="news-page-container">
      <header className="news-header">
        <h1>Latest News</h1>
        <p>Stay up to date with the latest news and updates from Your Way Support Services.</p>
      </header>

      <section className="news-articles">
        {articles.map((article, index) => (
          <div className="news-article" key={index}>
            <img src={article.image} alt={article.title} className="news-article-image" />
            <div className="news-article-content">
              <h2>{article.title}</h2>
              <p className="news-date">{article.date}</p>
              <p>{article.content}</p>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default NewsPage;
