import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/styles.css'; // Your custom CSS
import AboutUs from './AboutUs';
import Careers from './Careers';
import News from './News';
import image1 from './assets/img/image2.png';
import Logo from './assets/img/image0.png';
import 'bootstrap-icons/font/bootstrap-icons.css';

// ScrollToTop component to scroll to top on route change
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [expandedCard, setExpandedCard] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleCardClick = (cardIndex) => {
    setExpandedCard(expandedCard === cardIndex ? null : cardIndex);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Sending Form Data:", formData);

    emailjs.send(
      'service_vm3q3jc',
      'template_8arsbnm',
      {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      },
      'y1KIJW6wyQROcK8W_'
    )
    .then((result) => {
      console.log('Email sent successfully:', result.text);
      alert('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    }, (error) => {
      console.error('Failed to send email:', error.text);
      alert('Failed to send message: ' + error.text);
    });
  };

  // Function to handle closing of navbar when a link is clicked
  const handleNavbarToggle = () => {
    const navbarCollapse = document.getElementById('navbarResponsive');
    if (navbarCollapse && navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  };

  return (
    <Router>
      <ScrollToTop />
      <div id="page-top">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
  <div className="container px-5">
    <Link className="navbar-brand fw-bold d-flex align-items-center" to="/" onClick={handleNavbarToggle}>
      <img src={Logo} alt="Logo" className="logo-img me-2" style={{ height: '60px' }} />
      <span className="navbar-brand-text">YOUR WAY SPECIALIST SUPPORT</span>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <i className="bi-list"></i>
    </button>
    <div className="collapse navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
        <li className="nav-item">
          <Link className="nav-link me-lg-3" to="/about" onClick={handleNavbarToggle}>About Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link me-lg-3" to="/" state={{ scrollTo: 'features' }} onClick={handleNavbarToggle}>Services</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link me-lg-3" to="/news" onClick={handleNavbarToggle}>News</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link me-lg-3" to="/careers" onClick={handleNavbarToggle}>Careers</Link>
        </li>
      </ul>
      <div className="d-flex align-items-center">
      <button
  className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0 nav-contact-button"
  data-bs-toggle="modal"
  data-bs-target="#feedbackModal"
>
  <span className="d-flex align-items-center">
    <i className="bi-chat-text-fill me-2"></i>
    <span className="small">Contact Us</span>
  </span>
</button>

        <div className="d-flex ms-3">
          <a href="https://www.facebook.com/YourPage" target="_blank" rel="noopener noreferrer" className="mx-2">
            <i className="bi bi-facebook" style={{ fontSize: '1.5rem', color: '#1877F2' }}></i>
          </a>
          <a href="https://www.instagram.com/YourPage" target="_blank" rel="noopener noreferrer" className="mx-2">
            <i className="bi bi-instagram" style={{ fontSize: '1.5rem', color: '#C13584' }}></i>
          </a>
          <a href="https://www.tiktok.com/@YourPage" target="_blank" rel="noopener noreferrer" className="mx-2">
            <i className="bi bi-tiktok" style={{ fontSize: '1.5rem', color: '#010101' }}></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>


        <Routes>
          <Route path="/about" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/news" element={<News />} />
          <Route path="/" element={<Home expandedCard={expandedCard} handleCardClick={handleCardClick} />} />
        </Routes>

        {/* Modal */}
        <div className="modal fade" id="feedbackModal" tabIndex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary-to-secondary p-4">
                <h5 className="modal-title font-alt text-white" id="feedbackModalLabel">Contact Us</h5>
                <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body border-0 p-4">
                <form id="contactForm" onSubmit={handleSubmit}>
                  <div className="form-floating mb-3">
                    <input className="form-control" id="name" type="text" placeholder="Enter your name..." name="name" value={formData.name} onChange={handleChange} required />
                
                  </div>
                  <div className="form-floating mb-3">
                    <input className="form-control" id="email" type="email" placeholder="name@example.com" name="email" value={formData.email} onChange={handleChange} required />
                 
                  </div>
                  <div className="form-floating mb-3">
                    <textarea className="form-control" id="message" placeholder="Enter your message here..." style={{ height: '10rem' }} name="message" value={formData.message} onChange={handleChange} required></textarea>
                
                  </div>
                  <div className="d-grid">
                    <button className="btn btn-primary rounded-pill btn-lg" id="submitButton" type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer className="bg-white text-center py-5">
          <div className="container px-5">
            <div className="text-black-50 small mb-3">
              <a href="https://www.facebook.com/YourPage" target="_blank" rel="noopener noreferrer" className="mx-2">
                <i className="bi bi-facebook" style={{ fontSize: '1.5rem', color: '#1877F2' }}></i>
              </a>
              <a href="https://www.instagram.com/YourPage" target="_blank" rel="noopener noreferrer" className="mx-2">
                <i className="bi bi-instagram" style={{ fontSize: '1.5rem', color: '#C13584' }}></i>
              </a>
              <a href="https://www.tiktok.com/@YourPage" target="_blank" rel="noopener noreferrer" className="mx-2">
                <i className="bi bi-tiktok" style={{ fontSize: '1.5rem', color: '#010101' }}></i>
              </a>
            </div>
            <div className="text-black-50 small">
              <div className="mb-2">&copy; Your Way 2024. All Rights Reserved.</div>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

// Home component for the homepage
const Home = ({ expandedCard, handleCardClick }) => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to services section if 'scrollTo' state is present in the location
    if (location.state?.scrollTo === 'features') {
      const element = document.getElementById('features');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div>
      <header className="masthead">
        <div className="container px-5">
          <div className="row gx-5 align-items-center py-5">
            <div className="col-lg-6 text-center text-lg-start">
              <div className="mb-5 mb-lg-0">
                <h1 className="display-4 lh-1 mb-3" style={{ color: '#01ca9c' }}>
                  Welcome to <b>Your Way Specialist Support Services.</b>
                </h1>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <img src={image1} alt="Reminisce" className="img-fluid rounded-circle main-image" style={{ objectFit: 'cover' }} />
            </div>
          </div>
        </div>
      </header>

      <aside className="text-center bg-gradient-primary-to-secondary py-5">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-xl-8">
              <div className="h2 fs-1 text-white mb-4">"BESPOKE CARE AND SUPPORT, YOUR WAY"</div>
            </div>
          </div>
        </div>
      </aside>

      {/* Service Cards Section */}
      <section id="features" className="py-5">
        <div className="container px-5">
          <h2 className="display-4 lh-1 mb-4 text-center">Our Services</h2>
          <p className="text-center text-muted mb-4" style={{ fontSize: '1.1rem' }}>Click the card for more info</p>
          <div className="row gx-5 gy-4 justify-content-center">
            {/* Service Cards */}
            <ServiceCard
              cardIndex={1}
              expandedCard={expandedCard}
              handleCardClick={handleCardClick}
              icon="bi-house-door-fill"
              title="Bespoke Individual Tenancies"
              description="We will deliver specialist support to those who require intensive, individualised support and have more complex needs."
              expandedContent="This can be demonstrated in behavioural or physical needs, that require support from staff who have been equipped with specialised training and in an environment built or adapted to meet these needs in the safest possible way."
            />
            <ServiceCard
              cardIndex={2}
              expandedCard={expandedCard}
              handleCardClick={handleCardClick}
              icon="bi-people-fill"
              title="Core and Cluster Support"
              description="This model allows for independent living and privacy whilst also providing access to support."
              expandedContent="These sites may offer a communal area for people to socialise with neighbours and avoid isolation. More individualised 1:1 support can be offered for community activities."
            />
            <ServiceCard
              cardIndex={3}
              expandedCard={expandedCard}
              handleCardClick={handleCardClick}
              icon="bi-exclamation-triangle-fill"
              title="Emergency Accommodation"
              description="We offer temporary accommodation and support for people with a wide range of needs."
              expandedContent="Whether you or your family are looking for a break or your current placement has broken down due to staff shortages or being unable to manage behaviours, we can support either in your own home or in one of our services."
            />
            <ServiceCard
              cardIndex={4}
              expandedCard={expandedCard}
              handleCardClick={handleCardClick}
              icon="bi-house-heart-fill"
              title="Domiciliary Support"
              description="Supporting adults in the community with a learning disability and Autism to maintain independence and quality of life in their own homes."
              expandedContent="This helps people stay connected with communities and family, which is important for emotional and mental wellbeing."
            />
          </div>
        </div>
      </section>

<section className="cta">
  <div className="cta-content">
    <div className="container px-5">
      {/* Remove inline font-size style from h2 */}
      <h2 className="text-white display-4 lh-1 mb-3">
        <b>Your Way to personalised care and support.</b> <br />
        <br />
        <i>Contact us now.</i>
      </h2>
      {/* Update the button container */}
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
        <a
          href="tel:+00447512066889"
          className="btn btn-outline-light btn-sm py-2 px-4 rounded-pill d-flex align-items-center mb-2 mb-md-0 me-md-3"
        >
          <i className="bi bi-telephone-fill me-2"></i> 07512066889
        </a>
        <a
          className="btn btn-outline-light btn-sm py-2 px-4 rounded-pill"
          href="mailto:tony@yourwayss.com"
        >
          tony@yourwayss.com
        </a>
      </div>
    </div>
  </div>
</section>

    </div>
  );
};

// Service Card Component
const ServiceCard = ({ cardIndex, expandedCard, handleCardClick, icon, title, description, expandedContent }) => (
  <div className="col-md-6 d-flex">
    <div
      className={`service-card text-center p-4 shadow-sm rounded flex-fill ${expandedCard === cardIndex ? 'expanded-card' : ''}`}
      onClick={() => handleCardClick(cardIndex)}
      style={{ cursor: 'pointer', transition: 'all 0.3s ease' }}
    >
      <div className="service-icon mb-3">
        <i className={`bi ${icon}`} style={{ fontSize: '3rem', color: '#01fa60' }}></i>
      </div>
      <h4>{title}</h4>
      <p>{description}</p>
      {expandedCard === cardIndex && (
        <div className="expanded-content">
          <p>{expandedContent}</p>
        </div>
      )}
    </div>
  </div>
);

export default App;
